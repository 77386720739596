define("discourse/plugins/discourse-narrative-bot/initializers/new-user-narrative", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = dt7948.p({
    name: "new-user-narrative",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.discourse_narrative_bot_enabled) {
        return;
      }
      this.messageBus = container.lookup("service:message-bus");
      this.appEvents = container.lookup("service:app-events");
      (0, _pluginApi.withPluginApi)("0.8.7", api => {
        const currentUser = api.getCurrentUser();
        if (!currentUser) {
          return;
        }
        api.dispatchWidgetAppEvent("site-header", "header", "header:search-context-trigger");
        api.attachWidgetAction("header", "headerSearchContextTrigger", function () {
          if (this.site.mobileView) {
            this.state.skipSearchContext = false;
          } else {
            this.state.contextEnabled = true;
            this.state.searchContextType = "topic";
          }
        });
        this.messageBus.subscribe("/new_user_narrative/tutorial_search", this.onMessage);
      });
    },
    teardown() {
      this.messageBus?.unsubscribe("/new_user_narrative/tutorial_search", this.onMessage);
    },
    onMessage() {
      this.appEvents.trigger("header:search-context-trigger");
    }
  }, [["method", "onMessage", [_decorators.bind]]]);
});